<template>
    <div>
        <van-cell-group title="通道1">
            <van-cell :title="model.tongDao1SerialNumber + '(' + model.tongDao1Passageway + ')'" :value="model.tongDao1Temperature + '℃'" :label="'返回时间:' + model.tongDao1DataTime" value-class="font-red"></van-cell>
        </van-cell-group>
        <van-cell-group title="通道2">
            <van-cell :title="model.tongDao2SerialNumber + '(' + model.tongDao2Passageway + ')'" :value="model.tongDao2Temperature + '℃'" :label="'返回时间:' + model.tongDao2DataTime" value-class="font-red"></van-cell>
        </van-cell-group>
        <van-cell-group title="差值">
            <van-cell title="设定差值" :value="model.chaZhi + '℃'" value-class="font-red"></van-cell>
        </van-cell-group>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);
export default {
    data() {
        return { model: {} };
    },
    mounted() {
        document.title = "温差报警";
        let that = this;
        that.axios
            .post(
                "WXCW03_BJ/GetModel_BJRecord_WC",
                {},
                {
                    params: {
                        wcId: parseInt(that.$route.query.wcId),
                        tongDao1Id: parseInt(that.$route.query.tongDao1Id),
                        tongDao2Id: parseInt(that.$route.query.tongDao2Id),
                    },
                }
            )
            .then((response) => {
                console.log(response);
                that.model = response.data.data;
            });
    },
};
</script>

<style scoped>
.font-red {
    color: red;
    font-weight: bolder;
}
</style>